import {createGlobalStyle} from 'styled-components'

export const theme = {
  color: {
    dark: `#131F26`,
    mid: `#5A5A5A`,
    verbillion: `#FF4C00`,
    sea: `#B8E0DB`,
  },
  breakpoints: {
    major: '780px',
  }
}

export const GloblaStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    color: ${theme.color.sea};
    background-color: ${theme.color.dark};
    font-family: 'Rubik', sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
  }

  a {
    color: ${theme.color.verbillion};
  }
`